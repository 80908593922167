import React, { useRef } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { getOrderDetailsView } from "../_request";
import { useAuth } from "@app/modules/auth";
import { ToInr } from "@/Utils/Shared/CurrencyConverter.ts";
import useDocumentTitle from "@Hooks/useDocumentTitle";
import { DecorateNameTags } from "@/Utils/Shared/DecorateNameTags.ts";
import CommonPageHeader from "@components/helpers/CommonPageHeader/CommonPageHeader";
import DetailsCard from "../../../../../../components/UI/Cards/DetailsCard";
import OrderProductTable from "../../../../../../components/UI/Tables/OrderProductTable";
import { ORDER_TYPE } from "@Constants/Order_Constants";
import { StageItem } from "@components/helpers/StagesAccorion/CollapseStageAccordion.tsx";
import OrderDisclaimer from "./OrderView/OrderDisclaimer";
import NoteAlert from "../../../../../../components/UI/Alerts/NoteAlert";
import UseErrorRedirection from "@Hooks/useErrorRedirection.tsx";
import CommonBadge from "@components/helpers/CustomBadges/CommonBadge.tsx";
import PdfGenerateButton from "@components/helpers/Pdfmake/PdfGenerateButton.tsx";
import { generateOrderViewDocDefinition } from "@/Utils/pdfmake/generatePublicOrderViewService.ts";
import CardWithTextSkeleton from "@components/helpers/Skeletons/CardWithTextSkeleton.tsx";
import RowCardsSkeleton from "@components/helpers/Skeletons/RowCardsSkeleton.tsx";
import TableSkeleton from "@components/helpers/Skeletons/TableSkeleton.tsx";
import TextLinesSkeleton from "@components/helpers/Skeletons/TextLinesSkeleton.tsx";
import ListSkeleton from "@components/helpers/Skeletons/ListSkeleton.tsx";

const OrderDetailsView = () => {
  const { auth } = useAuth();
  const { slug } = useParams();

  //
  const containerRef = useRef(null);

  const {
    data: orderDetails,
    isLoading,
    isFetching,
  } = useQuery(["order-details-view", slug, auth?.access], () => {
    return getOrderDetailsView(auth?.access, slug)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  });
  UseErrorRedirection(orderDetails);
  useDocumentTitle({
    title: `Order #${orderDetails?.id} - ${orderDetails?.order_type_label} - ${orderDetails?.connect_info?.name}`,
  });

  return (
    <div className="container py-10">
      <div className={"row"}>
        <div className={"col"}>
          <CommonPageHeader
            id={orderDetails?.company}
            heading={"Order Details"}
          />
        </div>
      </div>

      <div className="row">
        <div className="col">
          <div className="card">
            <div className="card-body p-8">
              <div className="m-0 p-0 bg-white" ref={containerRef}>
                {/* Details cards */}
                {isFetching || isLoading ? (
                  <>
                    <div className="row row-cols-1 row-cols-md-2 row-cols-xl-3 gy-8 gx-xl-8 mb-8">
                      {Array.from({ length: 3 }).map((elem, index) => {
                        return (
                          <div className={"col"} key={index}>
                            <CardWithTextSkeleton />
                          </div>
                        );
                      })}
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      className={
                        "row row-cols-1 row-cols-md-2 row-cols-xl-3 gy-8 gx-xl-8 mb-8"
                      }
                    >
                      <div className={"col"}>
                        <DetailsCard
                          title={`Order ID - #${orderDetails?.slug}`}
                          rowsArray={[
                            {
                              label: "Order Type",
                              value: orderDetails?.order_type_label ?? "",
                            },
                            {
                              label: "Category",
                              value: (
                                <CommonBadge
                                  text={orderDetails?.category}
                                  theme={"odr_category"}
                                />
                              ),
                            },
                          ]}
                          cardClassNames={"border-gray-400"}
                        />
                      </div>
                      <div className={"col"}>
                        <DetailsCard
                          title={"Client Details"}
                          rowsArray={[
                            {
                              label: orderDetails?.connect_info?.name ?? "",
                              isImportant: true,
                            },
                            {
                              label: "Payment Terms",
                              value: DecorateNameTags(
                                orderDetails?.payment_terms ?? ""
                              ),
                            },
                          ]}
                          cardClassNames={"border-gray-400"}
                        />
                      </div>
                      <div className={"col"}>
                        <DetailsCard
                          title={"Payment Details"}
                          rowsArray={[
                            {
                              label: "Total Rate",
                              value: ToInr(orderDetails?.total_value) ?? "0.00",
                            },
                            {
                              label: "Net",
                              value:
                                ToInr(orderDetails?.total_with_gst) ?? "0.00",
                            },
                          ]}
                          cardClassNames={"border-gray-400"}
                        />
                      </div>
                    </div>
                  </>
                )}

                {orderDetails?.asset_request_details ? (
                  <>
                    <div className={"card border-gray-400 mb-8"}>
                      <div className="card-body">
                        <div className="hstack align-items-center gap-4">
                          <div className="card-title m-0 fs-3 fw-bold">
                            Request Details
                          </div>
                          <div className="fs-6 d-flex align-items-center gap-2">
                            <i className="ki-duotone ki-bi bi-lightning-charge-fill text-gold fs-2">
                              <span className="path1"></span>
                            </i>
                            <a
                              href={`${window.location.origin}/asset/request/${orderDetails?.asset_request_details?.slug}`}
                              target={"_blank"}
                              className={"link-primary fs-6 fw-medium"}
                              rel={"noopener noreferrer"}
                            >
                              {orderDetails?.asset_request_details?.slug}
                            </a>
                          </div>
                          <div className="">
                            <CommonBadge
                              text={
                                orderDetails?.asset_request_details
                                  ?.current_stage?.stage
                              }
                              theme={"ar_stage"}
                            />
                          </div>
                          <div
                            className={"text-gray-800 empty-none"}
                            dangerouslySetInnerHTML={{
                              __html:
                                orderDetails?.asset_request_details
                                  ?.current_stage?.note ?? "",
                            }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}

                {/* Ops processed and logistics processed */}
                {isFetching || isLoading ? (
                  <>
                    <RowCardsSkeleton />
                  </>
                ) : (
                  <>
                    <div className="row mb-8 g-8 gap-md-0">
                      <div className="col-md-6">
                        <div className="card border-gray-400">
                          <div className="card-body p-5">
                            <div className="d-flex align-items-center gap-4">
                              <div className="flex-shrink-0">
                                <div className={"btn btn-icon btn-ghost"}>
                                  <i className="bi bi-inbox-fill text-dark fs-1"></i>
                                </div>
                              </div>
                              <div className="flex-grow-1">
                                <h5 className="card-title m-0">
                                  Ready To Dispatch:&nbsp;
                                  {orderDetails?.ops_processed ?? "0"}
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="card border-gray-400">
                          <div className="card-body p-5">
                            <div className="d-flex align-items-center gap-4">
                              <div className="flex-shrink-0">
                                <div className={"btn btn-icon btn-ghost"}>
                                  <i className="bi bi-truck text-dark fs-1"></i>
                                </div>
                              </div>
                              <div className="flex-grow-1">
                                <h5 className="card-title m-0">
                                  Dispatched:{" "}
                                  {orderDetails?.logistics_processed ?? "0"}
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {/* Product Details */}
                {isFetching || isLoading ? (
                  <>
                    <TableSkeleton />
                  </>
                ) : (
                  <>
                    <div className="row mb-8">
                      <div className="col">
                        <div className={"card card-flush border-gray-400"}>
                          <div className="card-header bg-secondary-subtle px-6">
                            <div className="card-title">
                              <h2>Product Details</h2>
                            </div>
                          </div>
                          <div className={"card-body p-6"}>
                            <OrderProductTable
                              productItems={orderDetails?.items}
                              subTotal={orderDetails?.total_value ?? 0}
                              grandTotal={orderDetails?.total_with_gst ?? 0}
                              isSalesOrService={
                                orderDetails?.order_type === ORDER_TYPE.Sales ||
                                orderDetails?.order_type === ORDER_TYPE.Service
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {/* Notes or Terms */}
                {isFetching || isLoading ? (
                  <>
                    <TextLinesSkeleton />
                  </>
                ) : (
                  <>
                    {orderDetails?.note || orderDetails?.terms ? (
                      <div className="row mb-8 gy-8">
                        {orderDetails?.note && (
                          <div className="col-12">
                            <NoteAlert
                              heading={"Order Note"}
                              note={orderDetails?.note}
                              borderClass={"border-gray-400"}
                            />
                          </div>
                        )}
                        {orderDetails?.order_type_label === "SALES" &&
                          orderDetails?.terms && (
                            <div className="col-12">
                              <NoteAlert
                                heading={"Terms & Conditions"}
                                note={orderDetails?.terms}
                                borderClass={"border-gray-400"}
                              />
                            </div>
                          )}
                      </div>
                    ) : null}
                  </>
                )}

                {/* Order Stages */}
                {isFetching || isLoading ? (
                  <>
                    <ListSkeleton />
                  </>
                ) : (
                  <>
                    <div className="row">
                      <div className="col">
                        <div className="card border-gray-400">
                          <div className="card-body p-6 bg-white">
                            <StageItem
                              stage={orderDetails?.current_stage}
                              index={0}
                              paradimekey={"ODR"}
                              pfp={true}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>

          {/* Disclaimer */}
          <OrderDisclaimer />
        </div>
      </div>

      <div className="d-flex flex-center my-10">
        {orderDetails?.id ? (
          <>
            <PdfGenerateButton
              data={orderDetails}
              imageId={orderDetails?.company}
              generateFunction={generateOrderViewDocDefinition}
            />
          </>
        ) : null}
      </div>
    </div>
  );
};
export default OrderDetailsView;
