import { Content, TDocumentDefinitions } from "pdfmake/interfaces";
import {
  blaze,
  commonTableLayout,
  inbox,
  truck,
} from "@/Utils/pdfmake/_constants.ts";
import { PublicOrder } from "@/generated-api";
import { ToInr } from "@/Utils/Shared/CurrencyConverter.ts";
import { DecorateNameTags } from "@/Utils/Shared/DecorateNameTags.ts";
import { getCSSVariableValue } from "@metronic/assets/ts/_utils";
import {
  ORDER_CATEGORY_COLORS,
  ORDER_STAGES_COLORS,
  ORDER_TYPE,
} from "@Constants/Order_Constants";
import { RENT_REQUEST_STAGE_COLORS } from "@Constants/Rental_Constants";
import { longDate } from "@/Utils/Shared/DateStringToDays.ts";
import htmlToPdfmake from "html-to-pdfmake";

const border =
  "------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------";

function getOrderFirstRow({ data }: { data: PublicOrder }) {
  return {
    columnGap: 5,
    style: ["sectionGap"],
    columns: [
      {
        layout: commonTableLayout,
        table: {
          widths: ["*", "*", "*", "*", "*", "auto"],
          body: [
            [
              {
                text: `Order ID - #${data?.slug}`,
                colSpan: 2,
                style: ["tableHeader"],
              },
              {},
              { text: "Client Details", colSpan: 2, style: ["tableHeader"] },
              {},
              { text: "Payment Details", colSpan: 2, style: ["tableHeader"] },
              {},
            ],
            [
              { text: "Order Type" },
              { text: `${data?.order_type_label}`, alignment: "right" },
              {
                //   @ts-ignore
                text: `${DecorateNameTags(data?.connect_info?.name)}`,
                colSpan: 2,
              },
              {},
              { text: "Total Rate" },
              data?.total_value
                ? {
                    text: `${ToInr(+data?.total_value ?? 0)}`,
                    alignment: "right",
                  }
                : null,
            ],
            [
              { text: "Category" },
              {
                text: `${data?.category ?? ""}`,
                alignment: "right",
                color: getCSSVariableValue(
                  `--bs-${ORDER_CATEGORY_COLORS[data?.category ?? "default"]}`
                ),
              },
              { text: "Payment Terms" },
              {
                text: `${DecorateNameTags(data?.payment_terms)}`,
                alignment: "right",
              },
              { text: "Net" },
              data?.total_with_gst
                ? {
                    text: `${ToInr(+data?.total_with_gst ?? 0)}`,
                    alignment: "right",
                  }
                : null,
            ],
          ],
        },
      },
    ],
  } as Content;
}

function getRequestDetails({ data }: { data: PublicOrder }) {
  if (!data?.asset_request_details?.slug) {
    return null;
  }

  return {
    style: ["sectionGap"],
    layout: "noBorders",
    table: {
      widths: ["auto", "auto", "auto", "auto"],
      body: [
        [
          { text: "Request" },
          { svg: blaze },
          {
            text: data?.asset_request_details?.slug,
            link: `${window.location.origin}/asset/request/${data?.asset_request_details?.slug}`,
            color: getCSSVariableValue("--bs-primary"),
          },
          {
            text: "IN PROGRESS",
            color: getCSSVariableValue(
              `--bs-${
                RENT_REQUEST_STAGE_COLORS[
                  data?.asset_request_details?.current_stage?.stage ?? "default"
                ]
              }`
            ),
          },
        ],
      ],
    },
  } as Content;
}

function getDispatchData({ data }: { data: PublicOrder }) {
  return {
    style: ["sectionGap"],
    layout: "noBorders",
    table: {
      widths: ["auto", "*", "auto", "*"],
      body: [
        [
          { svg: inbox },
          {
            text: `Ready to dispatch:\t ${data.ops_processed ?? "0"}`,
            marginTop: 2,
          },
          { svg: truck },
          {
            text: `Dispatched:\t ${data.logistics_processed ?? "0"}`,
            marginTop: 2,
          },
        ],
      ],
    },
  } as Content;
}

function getProductItems({ data }: { data: PublicOrder }) {
  if (!data?.items) {
    return [];
  }

  const isSalesOrService =
    data?.order_type === ORDER_TYPE.Sales ||
    data?.order_type === ORDER_TYPE.Service;

  // @ts-ignore
  return data?.items.map((item) => {
    return [
      {
        stack: [
          { text: `${item?.item?.item ?? ""}`, style: ["tablePara"] },
          {
            text: `${item?.config ?? ""}`,
            style: ["tablePara"],
          },
        ],
      },
      {
        colSpan: isSalesOrService ? 2 : 1,
        stack: [
          {
            text: [
              { text: `${item?.consignee_details?.name}\t` },
              item?.consignee_details?.auto_id
                ? {
                    text: `#${item?.consignee_details?.auto_id}`,
                    style: ["muted"],
                  }
                : null,
            ],
            style: ["tablePara"],
          },
          {
            text: `${item?.consignee_details?.phone ?? ""}`,
            style: ["tablePara"],
          },
          {
            text: `${item?.consignee_details?.email ?? ""}`,
            style: ["tablePara"],
          },
          {
            text: `${
              item?.delivery_poc_address
                ? item?.delivery_poc_address
                : item?.consignee_poc_address ?? ""
            }`,
            style: ["tablePara"],
          },
        ],
      },
      { text: `${item?.qty ?? ""}`, alignment: "right" },
      isSalesOrService
        ? { text: `${item?.qty ?? ""}`, alignment: "right" }
        : { text: `${ToInr(item?.deposit ?? 0)}`, alignment: "right" },
      { text: `${ToInr(item?.rate ?? 0)}`, alignment: "right" },
      { text: `${ToInr(item?.qty * item?.rate)}`, alignment: "right" },
    ];
  });
}

function getProductDetails({ data }: { data: PublicOrder }) {
  const isSalesOrService =
    data?.order_type === ORDER_TYPE.Sales ||
    data?.order_type === ORDER_TYPE.Service;
  const subTotal = data?.total_value ? ToInr(+data?.total_value) : ToInr(0);
  const grandTotal = data?.total_with_gst
    ? ToInr(+data?.total_with_gst)
    : ToInr(0);
  const gst =
    data?.total_with_gst && data?.total_value
      ? ToInr(+data?.total_with_gst - +data?.total_value)
      : ToInr(0);

  return {
    style: ["sectionGap"],
    layout: commonTableLayout,
    table: {
      widths: ["*", "*", "auto", "auto", "auto", "auto"],
      body: [
        [
          { text: "PRODUCT", style: ["tableHeader"] },
          {
            text: "POC",
            style: ["tableHeader"],
            colSpan: isSalesOrService ? 2 : 1,
          },
          { text: "QTY", style: ["tableHeader"], alignment: "right" },
          isSalesOrService
            ? { text: "QTY", style: ["tableHeader"], alignment: "right" }
            : {
                text: "DEPOSIT *",
                style: ["tableHeader"],
                alignment: "right",
              },
          {
            text: `RATE ${isSalesOrService ? "" : "*"}`,
            style: ["tableHeader"],
            alignment: "right",
          },
          { text: "TOTAL", style: ["tableHeader"], alignment: "right" },
        ],
        ...getProductItems({ data }),
        [
          {
            text: "Subtotal",
            colSpan: isSalesOrService ? 4 : 5,
            alignment: "right",
          },
          {},
          {},
          isSalesOrService ? null : {},
          {},
          { text: subTotal, alignment: "right" },
        ],
        [
          {
            text: "GST (18%)",
            colSpan: isSalesOrService ? 4 : 5,
            alignment: "right",
          },
          {},
          {},
          isSalesOrService ? null : {},
          {},
          { text: gst, alignment: "right" },
        ],
        [
          {
            text: "Grand Total",
            style: ["tableFooter"],
            colSpan: isSalesOrService ? 4 : 5,
            alignment: "right",
          },
          {},
          {},
          isSalesOrService ? null : {},
          {},
          { text: grandTotal, style: ["tableFooter"], alignment: "right" },
        ],
      ],
    },
  } as Content;
}

function getOrderNotes({ data }: { data: PublicOrder }) {
  const note = htmlToPdfmake(`${data?.note ?? ""}`);
  const terms = htmlToPdfmake(`${data?.terms ?? ""}`);

  return {
    stack: [
      data?.note
        ? {
            stack: [{ text: "Order Note", style: ["normalParagraph"] }, note],
            style: ["normalParagraph"],
          }
        : null,
      data?.terms
        ? {
            stack: [
              { text: "Terms & Conditions", style: ["normalParagraph"] },
              terms,
            ],
            style: ["normalParagraph"],
          }
        : null,
    ],
  } as Content;
}

function getOrderStage({ data }: { data: PublicOrder }) {
  return {
    layout: {
      paddingTop: function () {
        return 4;
      },
      paddingBottom: function () {
        return 4;
      },
      paddingLeft: function () {
        return 8;
      },
      paddingRight: function () {
        return 8;
      },
      hLineColor: function () {
        return "#ccc";
      },
      vLineColor: function () {
        return "#ccc";
      },
    },
    marginBottom: 4,
    table: {
      widths: ["auto", "*"],
      body: [
        [
          {
            //   @ts-ignore
            text: `${data?.current_stage?.stage}`,
            color: getCSSVariableValue(
              `--bs-${
                //   @ts-ignore
                ORDER_STAGES_COLORS[data?.current_stage?.stage ?? "default"]
              }`
            ),
          },
          //   @ts-ignore
          { text: `${longDate(data?.current_stage?.created_at)}` },
        ],
      ],
    },
  } as Content;
}

interface GenerateOrderViewDD {
  data: PublicOrder;
  imageData: string;
}

export function generateOrderViewDocDefinition({
  data,
  imageData,
}: GenerateOrderViewDD) {
  const isSalesOrService =
    data?.order_type === ORDER_TYPE.Sales ||
    data?.order_type === ORDER_TYPE.Service;

  return Promise.resolve({
    info: {
      title: "Order Details",
    },
    content: [
      {
        image: "company",
        fit: [30, 30],
        width: 30,
        absolutePosition: { x: 40, y: 35 },
      },
      {
        text: "ORDER DETAILS",
        alignment: "center",
        fontSize: 20,
        characterSpacing: 1,
      },
      {
        text: border,
        decoration: "underline",
        decorationColor: "#999",
        decorationStyle: "dashed",
        color: "#fff",
        style: "sectionGap",
      },
      getOrderFirstRow({ data }),
      getRequestDetails({ data }),
      getDispatchData({ data }),
      getProductDetails({ data }),
      getOrderNotes({ data }),
      getOrderStage({ data }),
      {
        text: border,
        decoration: "underline",
        decorationColor: "#999",
        decorationStyle: "dashed",
        color: "#fff",
        style: "sectionGap",
      },
      {
        headlineLevel: 1,
        stack: [
          { text: "Disclaimer:", style: "normalParagraph" },
          {
            text: "The order details contain confidential information pertaining to the business relationship between the Company and its clients. By accessing the link, you agree to respect the privacy and confidentiality of this information. You agree not to disclose, disseminate, or use the information contained within the order details for any purpose that is not explicitly authorized by the Company.",
            style: "normalParagraph",
          },
          {
            text: "Unauthorized use, reproduction, or distribution of the order details or any information contained therein is strictly prohibited and may be subject to legal action.",
            style: "normalParagraph",
          },
          {
            text: "The Company reserves the right to track, restrict or revoke access to the Order Details Link at any time and for any reason, at its sole discretion.",
            style: "normalParagraph",
          },
        ],
      },
      {
        text: border,
        decoration: "underline",
        decorationColor: "#999",
        decorationStyle: "dashed",
        color: "#fff",
        style: "sectionGap",
      },
    ],
    images: {
      company: imageData,
    },
    styles: {
      tableHeader: {
        bold: true,
        color: "white",
        fillColor: "#555",
        noWrap: true,
      },
      header: {
        bold: true,
        fontSize: 12,
      },
      sectionGap: {
        marginBottom: 16,
      },
      normalParagraph: {
        marginBottom: 8,
      },
      muted: {
        color: "#999",
      },
      small: { fontSize: 9 },
      bold: { bold: true },
      tablePara: {
        marginBottom: 4,
      },
      tableFooter: {
        fontSize: 12,
        bold: true,
      },
    },
    defaultStyle: {
      columnGap: 20,
      fontSize: 10,
    },
    pageSize: "A4",
    pageMargins: 40,
    // pageBreakBefore: function (currentNode, followingNodesOnPage) {
    //   return (
    //     currentNode.headlineLevel === 1 && followingNodesOnPage.length === 0
    //   );
    // },
    footer: (currentPage, pageCount) => {
      return {
        stack: [
          isSalesOrService
            ? null
            : {
                text: [
                  { text: "Deposit * - Refundable\t", style: ["small"] },
                  { text: "Rate * - Monthly", style: ["small"] },
                ],
                marginLeft: 40,
                marginTop: 5,
              },
          {
            text: `${currentPage} / ${pageCount}`,
            absolutePosition: { x: 555, y: 2 },
          },
        ],
      };
    },
  } as TDocumentDefinitions);
}
